import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { GridRow, GridWrap } from '../Grid/Grid';

export interface Props {
  children: React.ReactNode;
  className?: string;
  variant?: 'information' | 'warning' | 'critical' | 'success';
}

const Container = styled.div<{ variant: NonNullable<Props['variant']> }>(
  ({ theme: { colours, shape, spacings }, variant }) => [
    css`
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      padding: ${spacings['16']}px;
      border: 1px solid ${colours.border[variant]};
      border-radius: ${shape.borderRadiusS}px;
      background: ${colours.surface[variant]};
      color: ${colours.text.on[variant]};
      gap: ${spacings['8']}px;
    `,
  ],
);

const InfoBox = ({ children, className, variant = 'information' }: Props) => {
  return (
    <GridWrap>
      <GridRow>
        <Container className={className} variant={variant}>
          {children}
        </Container>
      </GridRow>
    </GridWrap>
  );
};

export default InfoBox;
