import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import * as RxAccordion from '@radix-ui/react-accordion';
import { ColorFragment } from '@codegen/cmsUtils';
import { ReactComponent as DownArrowIcon } from '../../assets/icons/tiny-down-arrow.svg';
import { createTypography, shouldForwardProp } from '../../styles/base';
import { resetButton } from '../../styles/cssReset';

type AccordionVariant = 'blocks' | 'tiles';

const slideDown = keyframes`
  from { height: 0; }
  to { height: var(--radix-accordion-content-height); }
`;

const slideUp = keyframes`
  from { height: var(--radix-accordion-content-height); }
  to { height: 0; }
`;

const StyledAccordionItem = styled(RxAccordion.AccordionItem)<{
  variant: AccordionVariant;
}>(({ theme: { colours, shadows, shape, spacings }, variant }) => [
  variant === 'blocks' &&
    css`
      border-bottom: 1px solid ${colours.border.default};
      transition: border-color 250ms ease;

      &:hover {
        border-color: ${colours.border.interactive};
      }
    `,
  variant === 'tiles' &&
    css`
      padding: 0 ${spacings['16']}px;
      border-radius: ${shape.borderRadiusS}px;
      background: ${colours.surface.selected.default};
      box-shadow: ${shadows.medium};

      &:not(:last-child) {
        margin-bottom: ${spacings['8']}px;
      }
    `,
]);

const StyledAccordionHeader = styled.h3<{ headingColor: Maybe<ColorFragment> }>(
  ({ headingColor, theme: { colours, typography } }) => [
    createTypography(typography.heading05),
    css`
      color: ${headingColor?.main ?? colours.text.default};
    `,
  ],
);

const StyledAccordionHeading = styled.div<{ variant?: AccordionVariant }>(
  ({ theme: { spacings }, variant }) => [
    css`
      padding-right: ${spacings['8']}px;
    `,
    variant === 'tiles' &&
      css`
        [data-state='open'] & {
          font-weight: bold;
        }
      `,
  ],
);

const StyledAccordionTrigger = styled(RxAccordion.Trigger)(({ theme }) => [
  resetButton,
  css`
    display: flex;
    width: 100%;
    flex: 0 0 auto;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacings['16']}px 0;
    cursor: pointer;
    text-align: left;
  `,
]);

const StyledDownArrowIcon = styled(DownArrowIcon, {
  shouldForwardProp: (prop) =>
    shouldForwardProp({ prop, excludeProps: ['faqHeadingColor'] }),
})<{
  headingColor?: Maybe<ColorFragment>;
}>(({ headingColor, theme: { colours } }) => [
  css`
    width: 16px;
    height: 16px;
    flex: 0 0 auto;
    fill: ${headingColor?.main || colours.icons.subdued};
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);

    [data-state='open'] & {
      transform: rotate(180deg);
    }
  `,
]);

const StyledAccordionContent = styled(RxAccordion.Content)`
  overflow: hidden;

  [data-state='open'] & {
    animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  [data-state='closed'] & {
    animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
`;

const StyledAccordionContentInner = styled.div`
  padding-bottom: ${({ theme }) => theme.spacings['16']}px;
`;

interface AccordionItem {
  /** The content of the accordion item, shown when expanded */
  content: React.ReactNode;
  /** The heading of the item, shown in the accordion item's header */
  heading: React.ReactNode;
  /** An optional key value for the accordion item */
  value?: string;
}

export interface AccordionProps {
  /** An optional heading color, fallbacks to main text */
  headingColor?: Maybe<ColorFragment>;
  /** An array of accordion items, */
  items: AccordionItem[];
  /** Allow a single or multiple items to be open at once */
  type?: 'single' | 'multiple';
  /** The style variant of the accordion */
  variant?: AccordionVariant;
}

const Accordion = ({
  headingColor,
  items,
  type = 'multiple',
  variant = 'blocks',
}: AccordionProps) => {
  return (
    <RxAccordion.Root type={type}>
      {items.map((item, index) => (
        <StyledAccordionItem
          key={item.value ?? `Item-${index}`}
          value={item.value ?? `Item-${index}`}
          variant={variant}
        >
          <RxAccordion.Header asChild>
            <StyledAccordionHeader headingColor={headingColor}>
              <StyledAccordionTrigger>
                <StyledAccordionHeading variant={variant}>
                  {item.heading}
                </StyledAccordionHeading>
                <StyledDownArrowIcon headingColor={headingColor} />
              </StyledAccordionTrigger>
            </StyledAccordionHeader>
          </RxAccordion.Header>
          <StyledAccordionContent>
            <StyledAccordionContentInner>
              {item.content}
            </StyledAccordionContentInner>
          </StyledAccordionContent>
        </StyledAccordionItem>
      ))}
    </RxAccordion.Root>
  );
};

export default Accordion;
